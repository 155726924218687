<template>
  <div class="is-flex is-align-items-center" :class="horizontal ? 'is-flex-direction-row' : 'is-flex-direction-column'">
    <div v-if="$store.state.avatars.length" class="playerAvatar has-text-white is-flex is-justify-content-center is-align-items-center" :style="{
      width: `${scale * 20}px`,
      height: `${scale * 20}px`,
      backgroundColor: `#${player.color}`,
    }">
      <img v-if="!isError" :src="$store.state.avatars[player.avatar]?.image.sizes.thumbnail.url" @error="hideImage" />
      <d-icon icon="FaUser"/>

    </div>
    <div v-if="showUsername" class="username is-uppercase">{{ player.username }}</div>
  </div>
</template>

<script>
export default {
  props: {
    player: {
      type: Object,
      required: true,
    },
    scale: {
      type: Number,
      required: false,
      default: 1.5,
    },
    showUsername: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isError: false
    }
  },
  methods: {
    hideImage() {
      this.isError = true;
    }
  }
};
</script>
